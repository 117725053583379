import Link from 'next/link';
import { useState } from 'react';

import Dialog, { DialogActions, DialogContent, DialogTitle } from 'components/dialog';
import FaqItem from 'components/FaqItem';
import LayoutFaq from 'components/homepage/LayoutFaq';
import Picture from 'components/Picture';
import IconArrowRight from 'components/IconArrowRight';
import IllustrationDesign from 'components/IllustrationDesign';
import IllustrationFit from 'components/IllustrationFit';
import IllustrationShip from 'components/IllustrationShip';
import Homepage2x1ImageRight from 'components/homepage/Layout2x1ImageRight';
import HomePageTabs, { Tab } from 'components/HomePageTabs';
import NextSteps from 'components/NextSteps';
import SalesCalloutGroomsFree from 'components/SalesCalloutGroomsFree';
import SalesCalloutInspiration from 'components/SalesCalloutInspiration';
import BuySuitOrTuxSection from 'components/homepage/BuySuitOrTuxSection';
import ResponsiveContentBlocksWithTitle, {
  ResponsiveContentsWithTitleItem,
} from 'components/ResponsiveContentsWithTitle';
import SanityButton from 'components/sanity/Button';
import DoubleLineHeader from 'components/typography/DoubleLineHeader';
import SectionTitle from 'components/typography/SectionTitle';
import { TrendingBundleSlider } from 'components/homepage/WhatsTrending';
import { Testimonials } from 'components/homepage/Testimonials';
import { HeroImage } from './HeroImage';
import Disclaimer from './Disclaimer';

import tw from 'tailwind/tailwind-theme-gentux';

const trendingBundles = {
  forSpring: [
    {
      title: 'Black Notch Lapel Tuxedo',
      cost: '159',
      image: 'https://gentux.imgix.net/1643215828_GenerationTuxBlackNotchLapelTuxedoThreeQuatersShot.jpg',
      urlSlug: 'black-notch-lapel-tuxedo',
    },
    {
      title: 'British Tan Suit',
      cost: '159',
      image: 'https://gentux.imgix.net/1604728520_BritishTanSuitHero20201107.png',
      urlSlug: 'british-tan-notch-lapel-suit',
    },
    {
      title: 'Mystic Blue Suit',
      cost: '159',
      image: 'https://gentux.imgix.net/1642625262_GenerationTuxMysticBlueSuitThreeQuatersShot.jpg',
      urlSlug: 'mystic-blue-peak-lapel-suit',
    },
    {
      title: 'Gray Sharkskin Suit',
      cost: '159',
      image: 'https://gentux.imgix.net/1642623343_GenerationTuxGraySharkskinSuitthreequartersshot.jpg',
      urlSlug: 'gray-sharkskin-notch-lapel-suit',
    },
    {
      title: 'Slate Blue Suit',
      cost: '159',
      image: 'https://gentux.imgix.net/1643215325_GenerationTuxSlateBlueSuitThreeQuatersShot.jpg',
      urlSlug: 'slate-blue-notch-lapel-suit',
    },
    {
      title: 'Cement Gray Suit',
      cost: '149',
      image: 'https://gentux.imgix.net/1604728570_CementGraySuitHero20201107.png',
      urlSlug: 'cement-gray-notch-lapel-suit',
    },
  ],

  forFall: [
    {
      title: 'Black Shawl Lapel Tuxedo',
      cost: '149',
      image: 'https://gentux.imgix.net/1643216025_GenerationTuxBlackPeakLapelTuxedoThreeQuatersShot.jpg',
      urlSlug: 'black-shawl-lapel-tuxedo',
    },
    {
      title: 'Navy Blue Suit',
      cost: '159',
      image: 'https://gentux.imgix.net/1642625365_GenerationTuxNavyBlueSuitThreeQuatersShot.jpg',
      urlSlug: 'navy-blue-notch-lapel-suit',
    },
    {
      title: 'Black Suit',
      cost: '159',
      image: 'https://gentux.imgix.net/1642116329_GenerationTuxBlackSuitThreeQuatersShot.jpg',
      urlSlug: 'black-notch-lapel-suit',
    },
    {
      title: 'Hunter Green Suit',
      cost: '149',
      image: 'https://gentux.imgix.net/1712948327_HunterGreenNewArrivaljpg.jpg',
      urlSlug: 'hunter-green-notch-lapel-suit',
    },
    {
      title: 'Iron Gray Suit',
      cost: '159',
      image: 'https://gentux.imgix.net/1642625057_GenerationTuxIronGraySuitThreeQuatersShot.jpg',
      urlSlug: 'iron-gray-peak-lapel-suit',
    },
    {
      title: 'Allure Beige Suit',
      cost: '159',
      image: 'https://gentux.imgix.net/1636146360_GTVCLPBeigeHero2021110520400.jpg',
      urlSlug: 'allure-beige-suit',
    },
  ],
};

const whatsTrendingTabs: Tab[] = [
  {
    content: <TrendingBundleSlider bundles={trendingBundles.forSpring} />,
    title: 'Spring 2025',
  },
  {
    content: <TrendingBundleSlider bundles={trendingBundles.forFall} />,
    title: 'Fall 2025',
  },
];

const testimonials = [
  {
    content:
      'Our experience with Generation Tux was amazing. I had 8 fellas renting and none of us had a single issue. We were blown away with the quality of the suits. Top notch stuff guys. Cheers.',
    customerName: 'Austin D.',
  },
  {
    content:
      'From the customer service to the quality of the tuxedos, Generation Tux delivers only the best. I have been in a number of weddings over the years and this company has the best quality yet.',
    customerName: 'Matt S.',
  },
  {
    content:
      "Had Generation Tux for my Groom suit + 8 other groomsmen all located in different states. Timely shipping and A+ customer support for items that didn't fit perfectly. Would recommend Generation Tux to anyone.",
    customerName: 'Patrick W.',
  },
];

const suitsAndTuxedoRentalItems: ResponsiveContentsWithTitleItem[] = [
  {
    content: 'Free fabric swatches and a free Home Try-On to help wedding couples plan their big day.',
    href: '/how-it-works/weddings',
    picture: (
      <Picture
        alt={'Icon of a bride and groom in formal wear'}
        className="aspect-ratio-1by1"
        flip="h"
        focalPointY={0.25}
        focalPointZ={1.5}
        url={'https://gentux.imgix.net/weddings-lisa-hornak.jpg'}
      />
    ),
    title: 'Wedding Suit Rentals',
  },
  {
    content: 'Perfect for black-tie events, galas, quinceañeras, formals, or holiday parties.',
    href: '/how-it-works/special-events',
    picture: (
      <Picture
        alt={'Icon of a bride and groom in formal wear'}
        className="aspect-ratio-1by1"
        focalPointY={0.25}
        focalPointZ={1.5}
        url={'https://gentux.imgix.net/other-special-events-lisa-hornak.jpg'}
      />
    ),
    title: 'Special Event Rentals',
  },
  {
    content: 'Find your look for prom or homecoming with our stylish options.',
    href: '/how-it-works/prom',
    picture: (
      <Picture
        alt={'Icon of a man in a suit'}
        className="aspect-ratio-1by1"
        focalPointY={0.25}
        focalPointZ={1.5}
        url={'https://gentux.imgix.net/prom-lisa-hornak.jpg'}
      />
    ),
    title: 'Prom and Formal Rentals',
  },
];

const weddingCoupleItems = [
  {
    content: 'With 5 paid members, your rental is free. With 9 paid rentals, you can keep it too!',
    cta: {
      href: '/groomsfree',
      text: 'SEE OFFER',
    },
    href: '/groomsfree',
    picture: (
      <Picture
        alt={'A bride and groom kissing at their wedding'}
        className="aspect-ratio-1by1"
        focalPointY={0.25}
        url={'https://gentux.imgix.net/1661531634_GTKeepIt.jpg'}
      />
    ),
    title: 'Free Rental',
  },
  {
    content: 'Get expert styling tips for you and your entire party with a virtual consultation.',
    cta: {
      href: '/virtual-consultations?utm_content=hp_offers',
      text: 'SEE OFFER',
    },
    href: '/virtual-consultations?utm_content=hp_offers',
    picture: (
      <Picture
        alt={'A couple sitting on a couch, looking at a tablet'}
        className="aspect-ratio-1by1"
        focalPointX={0.65}
        focalPointY={0.25}
        focalPointZ={1.5}
        url={'https://gentux.imgix.net/couple-with-ipad.jpg'}
      />
    ),
    title: 'Free Virtual Consultation',
  },
  {
    content: 'Order 5 free fabric swatches to help you plan and get the perfect match.',
    cta: {
      href: '/swatches',
      text: 'SEE OFFER',
    },
    href: '/swatches',
    picture: (
      <Picture
        alt={'Photo of one of our swatch cards'}
        className="aspect-ratio-1by1"
        focalPointY={0.25}
        url={'https://gentux.imgix.net/1661531633_GTSwatches.jpg'}
      />
    ),
    title: 'Free Swatches',
  },
  {
    content: 'Try on your look from the comfort of your home. No commitment. Free shipping both ways.',
    cta: {
      href: '/home-try-on',
      text: 'SEE OFFER',
    },
    href: '/home-try-on',
    picture: (
      <Picture
        alt={'Rental suit on a box'}
        className="aspect-ratio-1by1"
        focalPointX={1}
        focalPointY={1}
        focalPointZ={1}
        url={`https://gentux.imgix.net/hto-jackets-thumbnail-2x.jpg`}
      />
    ),
    title: 'Free Home Try-On',
  },
];

const heroImageSources = [
  {
    key: 'md-up',
    mediaQuery: `(min-width: ${tw.screens.md})`,
    width: 2000,
    url: 'https://gentux.imgix.net/GT_BC_ForTheMoment_Hero_Edit_resized.jpg?auto=format,compress&fit=clip',
  },
  {
    key: 'sm-up',
    mediaQuery: `(min-width: ${tw.screens.sm})`,
    width: 1024,
    url: 'https://gentux.imgix.net/GT_BC_ForTheMoment_Hero_Edit_resized.jpg?auto=format&fit=clip&w=1024',
  },
  {
    key: 'base',
    width: 800,
    url: 'https://gentux.imgix.net/GT_BC_ForTheMoment_Hero_Edit_resized.jpg?auto=format&fit=clip&w=800',
  },
];

const RedesignedHomepage = () => {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      {/* Section */}
      <Homepage2x1ImageRight
        image={
          <Link href="/collection/tuxedos-and-suits" passHref>
            <a>
              <HeroImage
                sources={heroImageSources}
                aspectRatio="1.4 / 1"
                alt="A bride and groom who are holding hands, surrounded by friends and family"
              />
            </a>
          </Link>
        }
      />

      <SectionTitle
        line
        color="black"
        primary="What's Trending"
        tertiary="Browse our most popular styles by season"
        className="my-32 md:my-64"
      />

      <HomePageTabs className="mt-16 mb-32 px-32" data={whatsTrendingTabs} />

      {/* Section */}
      <div className="bg-gray-light">
        <BuySuitOrTuxSection
          title="New Collection"
          subtitle="Available for purchase"
          primaryCta={
            <SectionTitle
              primary="Buy your suit or tuxedo"
              tertiary="Premium suits and tuxedos designed in stylish fits for men and women."
              color="black"
              className="text-left"
            />
          }
          secondaryCta={
            <>
              <p className="text-h5 px-8 text-center uppercase text-gray-darker xs:text-h4">
                Compare{' '}
                <span className="cursor-pointer font-bold underline" onClick={() => setShowDialog(true)}>
                  renting & buying
                </span>{' '}
                with Generation Tux.
              </p>
            </>
          }
          leftBtn={{
            btnText: "SHOP MEN'S",
            href: 'https://buy.generationtux.com/collections/suits-tuxedos',
            trackerClass: 'tracker-cta-home_redesign-shop_mens-241126-152539',
          }}
          rightBtn={{
            btnText: "SHOP WOMEN'S",
            href: 'https://buy.generationtux.com/collections/womens-suits',
            trackerClass: 'tracker-cta-home_redesign-shop_womens-241126-152539',
          }}
        />
      </div>

      <div className="container">
        {/* Section */}
        <ResponsiveContentBlocksWithTitle
          className="mt-40 mb-64"
          items={suitsAndTuxedoRentalItems}
          title={
            <DoubleLineHeader className="pb-16 md:pb-32">
              {'ONLINE SUIT & TUXEDO RENTALS FOR EVERY OCCASION'}
            </DoubleLineHeader>
          }
          variant="small"
        />
      </div>

      <div className="bg-gold-lighter">
        <div className="row">
          <div className="col-span-12 md:order-2 md:col-span-7">
            <Link href="/spring-dream-giveaway" passHref>
              <a>
                <picture>
                  <source
                    key="md-up"
                    media={`(min-width: ${tw.screens.md})`}
                    srcSet="https://gentux.imgix.net/1743547625_GTGiftregistrygiveawaysocials1620x1080R4.jpg?auto=format"
                  />

                  <source
                    key="sm-up"
                    media={`(min-width: ${tw.screens.sm})`}
                    srcSet="https://gentux.imgix.net/1743547625_GTGiftregistrygiveawaysocials1620x1080R4.jpg?auto=format&fit=crop&w=660"
                  />

                  <source
                    key="base"
                    srcSet="https://gentux.imgix.net/1743547625_GTGiftregistrygiveawaysocials1620x1080R4.jpg?auto=format&fit=crop&w=500"
                  />

                  <img alt="Spring Dream Giveaway" className="block h-full w-full object-cover sm-only:object-fill" />
                </picture>
              </a>
            </Link>
          </div>
          <div className="col-span-12 space-y-24 py-40 px-24 md:order-1 md:col-span-5 md:px-1/7 xl:pt-1/6">
            <h3 className="text-h1-display">Win $1,000 towards your wedding registry</h3>
            <p className="text-gray-darker">
              Do you want to win <strong>$1,000*</strong> to spend on your wedding registry? All you have to do to be
              eligible is check out for your suit or tuxedo rental between April 6th and April 13th. Five qualifying
              customers will be chosen at random to win. <Link href="/spring-dream-giveaway">See details.</Link>
            </p>

            <a href="https://app.generationtux.com/account/login" className="btn-info btn w-full px-40 sm:w-auto">
              CHECK OUT NOW
            </a>
          </div>
        </div>
      </div>

      <div className="container">
        {/* Section */}
        <ResponsiveContentBlocksWithTitle
          className="my-64"
          items={weddingCoupleItems}
          title={
            <SectionTitle
              line
              color="gold-darker"
              primary="Wedding Couples"
              secondary="Special Offers for"
              tertiary="Take advantage of our free planning services to get the perfect wedding look."
            />
          }
          variant="large"
        />
      </div>

      <ResponsiveContentBlocksWithTitle
        className="bg-gray-lighter pt-32 pb-80 text-center"
        contents={[
          <div className="relative my-24 h-full px-32 md:my-0 md:pb-64 lg:px-40">
            <IllustrationDesign className="mx-auto mb-24" />
            <h4 className="text-h4 mt-24">Design your outfit in minutes.</h4>
            <div className="text-sm flex-1">
              Need style advice? Book a{' '}
              <Link href="/virtual-consultations?utm_content=hp_how_renting_works">
                <a className="font-bold text-gray-dark underline">Free Virtual Consultation</a>
              </Link>
              .
            </div>
            <SanityButton
              className="mt-24 md:absolute md:bottom-0 md:left-1/2 md:mt-0 md:-translate-x-1/2 md:transform"
              href="/collection/tuxedos-and-suits"
              newTab={false}
              style="btn-info"
              text="Browse rentals"
            />
          </div>,
          <div className="relative my-24 h-full px-32 md:my-0 md:pb-64 lg:px-40">
            <IllustrationFit className="mx-auto mb-24" />
            <h4 className="text-h4 mt-24">Easy online fitting for you and your wedding party.</h4>
            <div className="text-sm flex-1">
              Not sure about the fit? Order a{' '}
              <Link href="/home-try-on">
                <a className="font-bold text-gray-dark underline">Free Home Try-On</a>
              </Link>
              .
            </div>
            <SanityButton
              className="mt-24 md:absolute md:bottom-0 md:left-1/2 md:mt-0 md:-translate-x-1/2 md:transform"
              href="/how-it-works/fit-process"
              newTab={false}
              style="btn-info"
              text="Find your fit"
            />
          </div>,
          <div className="relative my-24 h-full px-32 md:my-0 md:pb-64 lg:px-40">
            <IllustrationShip className="mx-auto mb-24" />
            <h4 className="text-h4 mt-24">Get your order 14 days before your event.</h4>
            <div className="text-sm flex-1">Everyone gets their order with plenty of time for free replacements.</div>
            <SanityButton
              className="mt-24 md:absolute md:bottom-0 md:left-1/2 md:mt-0 md:-translate-x-1/2 md:transform"
              href="/collection/tuxedos-and-suits"
              newTab={false}
              style="btn-info"
              text="Rent now"
            />
          </div>,
        ]}
        style="contained"
        title={<SectionTitle className="mb-16 md:mb-64" color="black" primary="How Online Renting Works" />}
      />

      <Testimonials items={testimonials} />

      <div className="py-32 md:py-64">
        <LayoutFaq
          title="FAQ"
          cta={
            <Link href="/support/faq">
              <a className="text-gold-darker">
                <span className="flex">
                  <strong>{'MORE FAQS'}</strong> <IconArrowRight className="ml-8 text-gold-darker" />
                </span>
              </a>
            </Link>
          }
          full={true}
          prominent={true}
          faqItems={
            <>
              <FaqItem
                question="When will my rental order arrive?"
                trackerClass="tracker-btn-home-faq_arrival-241120-140809"
              >
                <p>
                  We ship your online suit or tuxedo rental to ensure it’s delivered 14 days ahead of your event,
                  allowing plenty of time for any adjustments. During peak seasons, delivery times may vary slightly, so
                  when your order arrives, we encourage opening the package right away and trying on the garments to
                  ensure the perfect fit.
                </p>
                <p>
                  If you encounter any issues, please{' '}
                  <Link href="/support">
                    <a className="text-gold-darker">contact us</a>
                  </Link>{' '}
                  within 48 hours so that we will have time to make any corrections and avoid rush shipping fees.
                </p>
                <p>
                  To learn about our process, see{' '}
                  <Link href="/how-it-works">
                    <a className="text-gold-darker">How It Works</a>
                  </Link>
                  .
                </p>
              </FaqItem>
              <FaqItem
                question="What if I have an issue with my fit?"
                trackerClass="tracker-btn-home-faq_fit-241120-140809"
              >
                <p>
                  When you receive your outfit, please open and try it on within 48 hours. If any item doesn't fit
                  properly,{' '}
                  <Link href="/support">
                    <a className="text-gold-darker">contact us</a>
                  </Link>{' '}
                  as soon as possible to determine if a free replacement order is necessary.
                </p>
                <p>
                  All items can be returned together in the pre-paid return bag we included with your order and can be
                  dropped off at your nearest UPS location.
                </p>
              </FaqItem>
              <FaqItem question="How do I return my rental?" trackerClass="tracker-btn-home-faq_return-241120-140809">
                <p>
                  Returning your suit or tuxedo rental is easy—just drop off the package at any nearby UPS location
                  within 3 days after your event.
                </p>
                <p>
                  Simply place all rental items in the prepaid return bag and use the prepaid UPS return label included
                  with your order. Please do not include plastic or hangers when shipping back.{' '}
                </p>
              </FaqItem>
              <FaqItem
                question="How do I care for my garments once I receive them?"
                trackerClass="tracker-btn-home-faq_care-241120-140809"
              >
                <p>
                  To ensure that you look sharp for your{' '}
                  <Link href="/how-it-works/weddings">
                    <a className="text-gold-darker">wedding</a>
                  </Link>
                  ,{' '}
                  <Link href="/how-it-works/prom">
                    <a>prom</a>
                  </Link>
                  , or{' '}
                  <Link href="/how-it-works/special-events">
                    <a className="text-gold-darker">special event</a>
                  </Link>
                  , make sure that after receiving your garments and trying them on, you hang up all garments to avoid
                  any wrinkles.
                </p>
                <p>
                  If necessary, you can use steam to remove wrinkles (using a fabric steamer or just a steamy bathroom).{' '}
                </p>
                <p>
                  Do not iron the garments. If you are experiencing more than minor wrinkling, please{' '}
                  <Link href="/support">
                    <a className="text-gold-darker">contact us</a>
                  </Link>{' '}
                  for assistance.
                </p>
              </FaqItem>
            </>
          }
        />
      </div>

      <NextSteps
        className="pt-64 pb-80"
        ctaPageName="home"
        salesCallout1={<SalesCalloutInspiration ctaPageName="home" />}
        salesCallout2={<SalesCalloutGroomsFree ctaPageName="home" />}
      />

      <Disclaimer className="container pt-0 pb-32">
        <p className="text-sm italic">
          * For terms and conditions, please visit our Spring Dream Giveaway page{' '}
          <Link href="/spring-dream-giveaway">
            <a className="text-gray-dark underline">HERE</a>
          </Link>{' '}
          to view
        </p>
      </Disclaimer>

      <Dialog className="md:h-4/6 xl:h-5/7 xl:w-5/7" open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogContent>
          <DialogTitle className="text-h2-display">{'Rent or Buy?'}</DialogTitle>

          <div className={'mt-8 p-24 sm:mt-24 sm:pt-0'}>
            <p>
              Renting or buying are both great options. Consider the benefits below, as well as how often you’ll wear
              the suit or tuxedo, your budget, and maintenance.
            </p>

            <div className="text-sm mt-24">
              <div className="flex space-x-8 pb-8">
                <h5 className="text-h5 w-1/2 flex-grow">Rent</h5>
                <h5 className="text-h5 w-1/2 flex-grow">Buy</h5>
              </div>
              <div className="flex space-x-8 border-t-1 border-gray-darker bg-gray-lighter py-16">
                <div className="w-1/2 flex-grow px-8">
                  Design your look online, get it delivered to your door, return 3 days after your event with free
                  shipping
                </div>
                <div className="w-1/2 flex-grow px-8">
                  Design your look online, get it delivered to your door, and it’s yours to keep
                </div>
              </div>
              <div className="flex space-x-8 py-16">
                <div className="w-1/2 flex-grow px-8">
                  Answer a few questions with our easy online fit algorithm to get the perfect fit
                </div>
                <div className="w-1/2 flex-grow px-8">Know your suit sizes and order your fit</div>
              </div>
              <div className="flex space-x-8 bg-gray-lighter py-16">
                <div className="w-1/2 flex-grow px-8">Super 130’s and 140’s Merino Wool fabric</div>
                <div className="w-1/2 flex-grow px-8">A comfortable cotton polyester blend</div>
              </div>
              <div className="flex space-x-8 py-16">
                <div className="w-1/2 flex-grow px-8">Free swatches and a Free Home Try-On to help you plan</div>
                <div className="w-1/2 flex-grow px-8">You know your style, order, and you’re done</div>
              </div>
              <div className="flex space-x-8 bg-gray-lighter py-16">
                <div className="w-1/2 flex-grow px-8">Arrives 14 days before your event</div>
                <div className="w-1/2 flex-grow px-8">Arrives within 3 &ndash; 7 days of purchase</div>
              </div>
              <div className="flex space-x-8 py-16">
                <div className="w-1/2 flex-grow px-8">Rental prices start at $139 for jacket and pants</div>
                <div className="w-1/2 flex-grow px-8">Purchase price starts at $200 for jacket and pants</div>
              </div>
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <a
            className="btn-info btn w-full"
            href="/collection/tuxedos-and-suits"
            rel="noopener noreferrer"
            target="_blank"
          >
            Browse Rentals
          </a>
          <a
            className="btn-info btn w-full"
            href="https://buy.generationtux.com/collections/suits-tuxedos"
            rel="noopener noreferrer"
            target="_blank"
          >
            Shop to Own
          </a>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RedesignedHomepage;
