import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

type DisclaimerProps = PropsWithChildren<{
  className?: string;
  columnClassName?: string;
}>;

const Disclaimer = (props: DisclaimerProps) => {
  if (!props.children) {
    return null;
  }

  const columnClassName = twMerge(
    'col-span-9 col-start-2 xs:col-span-8 xs:col-start-3 space-y-16',
    props.columnClassName ?? ''
  );

  return (
    <div className={props.className ?? 'container py-32 md:py-64'}>
      <div className="row">
        <div className={columnClassName}>{props.children}</div>
      </div>
    </div>
  );
};

export default Disclaimer;
