import { createRef, PropsWithChildren, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { twMerge } from 'tailwind-merge';

import IconX from 'components/IconX';
import useClickOutside from 'hooks/useClickOutside';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
import useOnEscapePress from 'hooks/useOnEscapePress';

type DialogProps = PropsWithChildren<
  {
    onClose: () => void;
    open: boolean;
    showCloseButton?: boolean;
  } & ClassNameProp
>;

const Dialog = ({ children, className, onClose, open, showCloseButton = true }: DialogProps) => {
  const ref = createRef<HTMLDivElement>();

  useClickOutside(ref, () => onClose());
  useOnEscapePress(onClose);
  useLockBodyScroll(open, ref);

  // add modal to root of DOM to prevent z-index collisions
  const rootElemRef = useRef<HTMLElement | null>(null);
  useEffect(() => {
    rootElemRef.current = document.body;

    return function removeElement() {
      rootElemRef.current = null;
    };
  }, []);

  useEffect(() => {
    if (open) {
      ref.current?.classList.add('animate-fadeIn');
    } else {
      ref.current?.classList.remove('animate-fadeIn');
    }

    return () => ref.current?.classList.remove('animate-fadeIn');
  }, [open]);

  if (!rootElemRef.current) {
    return null;
  }

  return ReactDOM.createPortal(
    open && (
      <div className="fixed inset-0 z-50 box-border h-full w-full bg-black/30 pt-40 sm:pt-0">
        <div
          className={twMerge(
            'sm:absolute-center relative flex h-full w-full flex-col justify-between bg-white pt-2 text-gray-darker sm:h-3/5 sm:w-5/6 md:h-3/5 md:w-3/5',
            className
          )}
          ref={ref}
        >
          {showCloseButton && (
            <div
              className="absolute top-24 right-24 rounded-full bg-gray-lighter p-8"
              onClick={(ev) => {
                ev.stopPropagation();
                onClose();
              }}
            >
              <button className="text-lg text-gray-dark">
                <IconX className="text-xs inline h-24 w-24 cursor-pointer text-gray-dark" />
              </button>
            </div>
          )}

          {children}
        </div>
      </div>
    ),
    rootElemRef.current
  );
};

Dialog.displayName = 'Dialog';

export { default as DialogActions } from 'components/dialog/Actions';
export { default as DialogContent } from 'components/dialog/Content';
export { default as DialogTitle } from 'components/dialog/Title';

export default Dialog;
